<template>
  <div>
    <van-field
      readonly
      clickable
      name="picker"
      label="店铺类型"
      :value="name"
      placeholder="选择店铺类型"
      :rules="[{ required: true, message: '请选择店铺类型' }]"
      is-link
      @click="showPicker = true"
    />
    <hr
      style="width: 91.161%; size:50px; color: #9f9f9f; text-align: center; margin: 0;border: 0"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="店铺类型"
        show-toolbar
        :columns="shopTypeOptions"
        value-key="text"
        :colunms-field-names="{ text: 'label' }"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
  import { getShopType } from '@/api/base/base'

  export default {
    name: 'ShopType',
    props: {
      shopType: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        value: {},
        name: '',
        showPicker: false,
        shopTypeOptions: [],
      }
    },
    created() {
      this.getShopTypeOptions()
    },
    methods: {
      //选值触发，回调方法返回选项value值
      onConfirm(value) {
        this.name = value.text
        this.value = value
        this.$emit('shopTypeChange', value.value)
        this.showPicker = false
      },

      //获取店铺类型选项
      async getShopTypeOptions() {
        const { data } = await getShopType()
        this.shopTypeOptions = data

        //有值则回绑
        if (this.shopType != null) this.bindOption()
      },

      //回绑方法
      bindOption() {
        this.shopTypeOptions.forEach((option) => {
          if (option.value == this.shopType) {
            this.name = option.text
            this.value = { text: option.text, value: this.shopType }
          }
        })
      },

      //清空店铺类型选择器
      reset() {
        this.name = ''
        this.value = {}
      },

      //刷新
      async render(shopType) {
        const { data } = await getShopType()
        this.shopTypeOptions = data
        this.shopTypeOptions.forEach((option) => {
          if (option.value == shopType) {
            this.name = option.text
            this.value = { text: option.text, value: this.shopType }
          }
        })
      },
    },
  }
</script>

<style scoped></style>
