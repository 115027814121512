import request from '@/util/request'

export function addShopGrade(params) {
  return request({
    url: '/ShopGrade.asmx/AddShopGrade',
    method: 'GET',
    params,
  })
}

export function getShopGradeList(params) {
  return request({
    url: '/ShopGrade.asmx/GetShopGradeList',
    method: 'GET',
    params,
  })
}

export function getShopGradeInfo(params) {
  return request({
    url: '/ShopGrade.asmx/GetShopGradeInfo',
    method: 'GET',
    params,
  })
}
