<template>
  <div style="margin:14px">
    <van-nav-bar title="店铺评分" />
    <van-form @submit="onSubmit">
      <van-field
        v-model="formData.shopName"
        name="shopName"
        label="店铺名称"
        readonly
      />
      <ShopType
        ref="shopType"
        :shop-type="formData.shopType"
        @shopTypeChange="shopTypeChange"
      />
      <van-field
        readonly
        clickable
        label="管理承诺书"
        :value="formData.outDoor"
        placeholder="管理承诺书"
        is-link
        @click="showOutDoorPicker = true"
      />
      <van-popup v-model="showOutDoorPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="outDoorOptions"
          @cancel="showOutDoorPicker = false"
          @confirm="outDoorConfirm"
        />
      </van-popup>
      <van-field
        type="textarea"
        rows="3"
        autosize
        readonly
        clickable
        :value="optionView.health"
        label="卫生方面"
        placeholder="请选择卫生情况"
        is-link
        @click="showHealPicker = true"
      />
      <van-popup v-model="showHealPicker" round position="bottom">
        <van-checkbox-group v-model="healthResult">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in healthOptions"
              :key="item.value"
              clickable
              :title="`${item.text}`"
              @click="healthToggle(index)"
            >
              <template #right-icon>
                <van-checkbox ref="checkboxes1" :name="item.value" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div style="margin: 40px;">
          <van-button
            round
            block
            type="info"
            size="normal"
            native-type="button"
            @click="healthSave"
          >
            确定
          </van-button>
        </div>
      </van-popup>
      <van-field
        type="textarea"
        rows="3"
        autosize
        readonly
        clickable
        :value="optionView.order"
        label="秩序方面"
        placeholder="请选择秩序情况"
        is-link
        @click="showOrderPicker = true"
      />
      <van-popup v-model="showOrderPicker" round position="bottom">
        <van-checkbox-group v-model="orderResult">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in orderOptions"
              :key="item.value"
              clickable
              :title="`${item.text}`"
              @click="orderToggle(index)"
            >
              <template #right-icon>
                <van-checkbox ref="checkboxes2" :name="item.value" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div style="margin: 40px;">
          <van-button
            round
            block
            type="info"
            size="normal"
            native-type="button"
            @click="orderSave"
          >
            确定
          </van-button>
        </div>
      </van-popup>
      <van-field
        type="textarea"
        rows="3"
        autosize
        readonly
        clickable
        :value="optionView.eqmAndPlant"
        label="设备和绿化"
        placeholder="请选择设备和绿化情况"
        is-link
        @click="showEqmAndPlantPicker = true"
      />
      <van-popup v-model="showEqmAndPlantPicker" round position="bottom">
        <van-checkbox-group v-model="eqmAndPlantResult">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in eqmAndPlantOptions"
              :key="item.value"
              clickable
              :title="`${item.text}`"
              @click="eqmAndPlantToggle(index)"
            >
              <template #right-icon>
                <van-checkbox ref="checkboxes3" :name="item.value" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div style="margin: 40px;">
          <van-button
            round
            block
            type="info"
            size="normal"
            native-type="button"
            @click="eqmAndPlantSave"
          >
            确定
          </van-button>
        </div>
      </van-popup>
      <van-field
        v-show="
          formData.shopType != 10 &&
            formData.shopType != 11 &&
            formData.shopType != 0 &&
            formData.shopType != null
        "
        type="textarea"
        rows="3"
        autosize
        readonly
        clickable
        :value="optionView.businessRequire"
        label="行业要求"
        placeholder="请选择行业要求情况"
        is-link
        @click="showBusinessRequirePicker = true"
      />
      <van-popup v-model="showBusinessRequirePicker" round position="bottom">
        <van-checkbox-group v-model="businessRequireResult">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in businessRequireOptions"
              :key="item.value"
              clickable
              :title="`${item.text}`"
              @click="businessRequireToggle(index)"
            >
              <template #right-icon>
                <van-checkbox ref="checkboxes4" :name="item.value" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div style="margin: 40px;">
          <van-button
            round
            block
            type="info"
            size="normal"
            native-type="button"
            @click="businessRequireSave"
          >
            确定
          </van-button>
        </div>
      </van-popup>
      <van-field label="参加街道部门组织活动">
        <template #input>
          <van-stepper v-model="formData.activity" min="0" max="20" />
        </template>
      </van-field>
      <van-field label="示范单位">
        <template #input>
          <van-radio-group v-model="isExampleShop">
            <van-radio name="1">
              该路段内带头落实门前三包责任制，上月被评为该路段示范单位
            </van-radio>
            <van-radio name="0">无</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field readonly label="上传照片" />
      <div style="padding: 10px;background: white">
        <upload-file :limit="2" @fetch-data="fetchShots" />
      </div>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { Dialog } from 'vant'
  import moment from 'moment'
  import Base64 from '@/util/Base64'
  import store from '@/store'
  import uploadFile from '@/components/UploadFile'
  import ShopType from '@/components/ShopType'
  import { addShopGrade } from '@/api/outDoor/shopGrade/shopGrade'
  import { getShopInfo } from '@/api/workBench/shop/shop'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ShopAdd',
    components: {
      ShopType,
      uploadFile,
    },
    data() {
      return {
        showOutDoorPicker: false,
        showHealPicker: false,
        showOrderPicker: false,
        showEqmAndPlantPicker: false,
        showBusinessRequirePicker: false,
        healthArr: [],
        orderArr: [],
        eqmAndPlantArr: [],
        businessRequireArr: [],
        shotsArr: [], //决策书照片
        optionView: {
          health: '',
          order: '',
          eqmAndPlant: '',
          businessRequire: '',
        },
        isExampleShop: '0',

        healthResult: [],
        orderResult: [],
        eqmAndPlantResult: [],
        businessRequireResult: [],
        outDoorOptions: [
          '取消选择',
          '未签订门前三包责任书',
          '未悬挂管理承诺书',
          '未悬挂在醒目位置',
        ],
        healthOptions: [
          { text: '门前散落垃圾、杂物', value: 1 },
          { text: '门前污水、污垢、油渍或严重积尘', value: 2 },
          { text: '未做好垃圾分类', value: 3 },
          { text: '卫生设施不完好、不整洁、破损', value: 4 },
        ],
        orderOptions: [
          { text: '门前乱吊挂', value: 1 },
          { text: '门前乱晾晒', value: 2 },
          { text: '门前乱占道', value: 3 },
          { text: '门前乱堆放', value: 4 },
          { text: '门前乱张贴', value: 5 },
          { text: '门前乱涂写', value: 6 },
          { text: '门前乱停放', value: 7 },
          {
            text:
              '对其他行为人的乱停、乱靠、乱设摊、乱挖掘等影响市容秩序的行为未履行监督、劝说、举报责任',
            value: 8,
          },
        ],
        eqmAndPlantOptions: [
          { text: '责任区内设施不完好', value: 1 },
          { text: '店招、店牌、遮阳棚等不规范美观', value: 2 },
          { text: '店招、店牌、遮阳棚等不整洁有破损', value: 3 },
          { text: '对损坏公共设施的行为未履行制止、举报责任', value: 4 },
          {
            text: '责任区内绿化不美观，有攀折树木，有践踏、占用、破坏绿化现象',
            value: 5,
          },
          { text: '有在绿化带内倾倒污水、垃圾的行为', value: 6 },
        ],
        businessRequireOptions: [
          { text: '未安装油烟净化装置、隔油池等设施', value: 1 },
          {
            text: '未对油烟净化装置、隔油池等设施正常使用、定期清理',
            value: 2,
          },
          { text: '排油烟口墙面不整洁，有明显污渍', value: 3 },
          { text: '未依法办理排水许可', value: 4 },
          { text: '未按规定排放污水，及时清理沉淀池', value: 5 },
          { text: '气瓶没有实名制', value: 6 },
          {
            text:
              '气瓶安全安装、存放、使用，软管有过长、穿墙、老化情况，瓶体有加热、倒卧现象',
            value: 7,
          },
        ],
        formData: {
          shopId: null, //店铺ID
          shopName: '',
          shopType: null,
          activity: 0, //参与活动次数
          outDoor: '',
          health: '', //卫生方面
          order: '', //秩序方面
          eqmAndPlant: '', //设备和绿化
          businessRequire: '', //行业要求
          shots: '', //照片
          userId: null,
          exampleShop: '0', //是否示范单位
          totalGrade: 0,
        },
      }
    },
    async created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      this.formData.userId = parseInt(userInfo.User_ID)
      this.formData.shopId = Base64.decode(this.$route.query.shopID)
      this.formData.shopName = Base64.decode(this.$route.query.shopName)
      console.log(this.formData.shopId)
      await this.getShopType(this.formData.shopId)
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopGradeAdd',
        '城管-店铺评分新增',
        'zzdcg.yy.gov.cn/shopGradeAdd'
      )
    },
    methods: {
      //提交
      async onSubmit() {
        this.formData.shots = this.shotsArr.join('|')
        if (!this.formData.shots.length > 0) {
          await Dialog.alert({
            message: '请上传照片',
          })
          return
        }
        this.formData.exampleShop = Number(this.isExampleShop)
        //计算卫生、秩序、设备绿化、行业要求扣分,示范单位加分
        let score = 0
        score =
          this.healthArr.length > 6
            ? score + 30
            : score + this.healthArr.length * 5
        score =
          this.orderArr.length > 8
            ? score + 40
            : score + this.orderArr.length * 5
        score =
          this.eqmAndPlantArr.length > 4
            ? score + 20
            : score + this.eqmAndPlantArr.length * 5
        if (this.isExampleShop != '0') score -= 5
        this.formData.totalGrade = score
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { msg, success } = await addShopGrade({
          ShopID: this.formData.shopId,
          ShopName: this.formData.shopName,
          ShopType: this.formData.shopType,
          Activity: this.formData.activity,
          ExampleShop: this.formData.exampleShop,
          TotalGrade: this.formData.totalGrade,
          UserID: this.formData.userId,
          OutDoor: this.formData.outDoor,
          Health: this.formData.health,
          Order: this.formData.order,
          EqmAndPlant: this.formData.eqmAndPlant,
          BusinessRequire: this.formData.businessRequire,
          Shots: this.formData.shots,
        })
        Toast.clear()
        Dialog.alert({
          message: '' + msg,
        }).then(() => {
          if (success) {
            this.toList()
          }
        })
      },

      //跳转至评分记录
      toList() {
        this.$router.replace({
          path: '/shopGrade',
          query: {
            shopID: Base64.encode(this.formData.shopId),
          },
        })
      },

      //获取店铺类型
      async getShopType(shopId) {
        const { data } = await getShopInfo({
          Shop_ID: this.formData.shopId,
          KeyWord: '',
          Type: 2,
          User_ID: '',
        })
        this.formData.shopType = data.Shop_Type
        this.$refs.shopType.render(data.Shop_Type)
      },

      //多选直接点击选项而不是选择框也能起效
      healthToggle(index) {
        this.$refs.checkboxes1[index].toggle()
      },
      orderToggle(index) {
        this.$refs.d[index].toggle()
      },
      eqmAndPlantToggle(index) {
        this.$refs.checkboxes3[index].toggle()
      },
      businessRequireToggle(index) {
        this.$refs.checkboxes4[index].toggle()
      },

      //选择器保存方法
      shopTypeChange(shopType) {
        this.formData.shopType = shopType
        this.optionView.businessRequire = ''
        this.formData.businessRequire = ''
      },
      outDoorConfirm(value, index) {
        //留一个取消选择选项
        this.formData.outDoor = index == 0 ? '' : value
        this.showOutDoorPicker = false
      },
      healthSave() {
        this.healthArr = []
        this.formData.health = ''
        this.healthOptions.forEach((item) => {
          if (this.healthResult.includes(item.value)) {
            this.healthArr.push(item.text)
          }
        })
        this.optionView.health = this.healthArr.join('\n')
        this.formData.health = this.healthArr.join('，')
        this.showHealPicker = false
      },
      orderSave() {
        this.orderArr = []
        this.formData.order = ''
        this.orderOptions.forEach((item) => {
          if (this.orderResult.includes(item.value)) {
            this.orderArr.push(item.text)
          }
        })
        this.optionView.order = this.orderArr.join('\n')
        this.formData.order = this.orderArr.join('，')
        this.showOrderPicker = false
      },
      eqmAndPlantSave() {
        this.eqmAndPlantArr = []
        this.formData.eqmAndPlant = ''
        this.eqmAndPlantOptions.forEach((item) => {
          if (this.eqmAndPlantResult.includes(item.value)) {
            this.eqmAndPlantArr.push(item.text)
          }
        })
        this.optionView.eqmAndPlant = this.eqmAndPlantArr.join('\n')
        this.formData.eqmAndPlant = this.eqmAndPlantArr.join('，')
        this.showEqmAndPlantPicker = false
      },
      businessRequireSave() {
        this.businessRequireArr = []
        this.formData.businessRequire = ''
        this.businessRequireOptions.forEach((item) => {
          if (this.businessRequireResult.includes(item.value)) {
            this.businessRequireArr.push(item.text)
          }
        })
        this.optionView.businessRequire = this.businessRequireArr.join('\n')
        this.formData.businessRequire = this.businessRequireArr.join('，')
        this.showBusinessRequirePicker = false
      },

      //照片上传回调方法
      fetchShots(pathList) {
        this.shotsArr = pathList
      },
    },
  }
</script>

<style scoped></style>
