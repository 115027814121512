<template>
  <div>
    <van-uploader
      v-model="fileList"
      multiple
      :after-read="afterRead"
      :before-delete="beforeDelete"
      :max-count="limit"
    />
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { uploadFile } from '@/api/base/base'

  export default {
    name: 'UploadFile',
    props: {
      limit: { type: Number, default: () => 1 },
    },
    data() {
      return {
        fileList: [], //原始文件
        pathList: [], //服务器返回路径
      }
    },
    methods: {
      async afterRead(params) {
        const formData = new FormData()
        formData.append('file', params.file)
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { success, data } = await uploadFile(formData)
        Toast.clear()
        if (success) {
          this.pathList.push(data)
        } else {
          Toast('上传失败')
          this.fileList.pop()
        }
        this.$emit('fetch-data', this.pathList)
      },

      beforeDelete(file, index) {
        this.fileList.filter((item, indexs) => {
          if (index.index === indexs) {
            this.pathList.splice(indexs, 1)
          }
        })
        this.$emit('fetch-data', this.pathList)
        return true
      },
    },
  }
</script>

<style scoped></style>
